import { Link, useLocation, useParams, useMatch } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import { useModal } from "../context";

function numberWithSpaces(value, pattern) {
  let i = 0,
    phone = value.toString();
  return pattern.replace(/#/g, (_) => phone[i++]);
}

function Header() {
  const [nav, setNav] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const { show, hide } = useModal();
  const [inner, setInner] = useState(false);

  const home = path == "/";

  useEffect(() => {
    window.scrollTo(0, 0);
    setNav(false);
  }, [location.pathname]);

  const goToAnchor = (anchor) => {
    let el = document.getElementById(anchor);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }
    setNav(false);
  };

  return (
    <div id="header" className="header">
      <div className="container">
        <div
          onClick={() => {
            setNav(!nav);
          }}
          className={"mb-only nav__bars" + (nav ? " active" : "")}
        >
          <svg
            width="47"
            height="35"
            viewBox="0 0 47 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="30" width="47" height="5" fill="#F0D264" />
            <rect y="15" width="47" height="5" fill="#F0D264" />
            <rect width="47" height="5" fill="#F0D264" />
          </svg>
          <div className="nav__bars-close">
            <svg
              width="37"
              height="37"
              viewBox="0 0 37 37"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="3.65088"
                y="0.115234"
                width="47"
                height="5"
                transform="rotate(45 3.65088 0.115234)"
                fill="#F0D264"
              />
              <rect
                x="36.8848"
                y="3.65088"
                width="47"
                height="5"
                transform="rotate(135 36.8848 3.65088)"
                fill="#F0D264"
              />
            </svg>
          </div>
        </div>
        <Link
          to="/"
          onClick={() => {
            setNav(false);
          }}
          className="mb-only hd__logo"
        >
          <svg
            width="65"
            height="40"
            viewBox="0 0 65 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.85925 9.79541H12.2897L15.1921 18.1422C15.4043 18.8251 15.6166 19.8258 15.6166 19.8258H15.6635C15.6635 19.8258 15.8523 18.8251 16.0646 18.1422L18.967 9.79541H21.3734L16.9611 22.1437H14.3425L9.85979 9.79541H9.85925Z"
              fill="#14325F"
            />
            <path
              d="M28.9525 27.0648C28.9525 28.6535 27.7029 29.9465 26.1664 29.9465H5.06507C3.52907 29.9465 2.27893 28.654 2.27893 27.0648V5.23941C2.27893 3.65071 3.52853 2.35768 5.06507 2.35768H26.1664C27.6357 2.35768 28.8565 3.54424 28.9461 5.05847L28.9525 5.16604V7.17564H31.232V4.9145H31.2213C31.0592 2.17233 28.8587 0 26.1664 0H5.06507C2.26773 0 0 2.34554 0 5.23941V27.0648C0 29.9581 2.26773 32.3036 5.06507 32.3036H26.1664C28.9637 32.3036 31.2315 29.9581 31.2315 27.0648V24.7639H28.952V27.0648H28.9525ZM34.7947 15.2378L38.7584 9.7959H36.1632L32.9307 14.3353H31.2315V9.7959H28.952V22.1442H31.2315V16.3609H32.9776L36.5403 22.1442H39.2064L34.7941 15.2868V15.2378H34.7947ZM46 9.50298C42.6027 9.50298 40.0069 12.041 40.0069 15.9703C40.0069 19.6304 42.6027 22.4371 46.3541 22.4371C48.0059 22.4371 49.8224 21.779 50.9787 20.6802L50.0112 18.948C49.0672 19.8262 47.7696 20.3878 46.4955 20.3878C44.3483 20.3878 42.5083 18.948 42.3899 16.2875H51.0725C51.1195 15.9461 51.1669 15.6046 51.1669 15.2868C51.1669 12.1144 49.3739 9.50298 46 9.50298ZM42.4613 14.5786C42.7915 12.5287 44.1835 11.3333 45.9531 11.3333C47.5104 11.3333 48.7845 12.4559 48.8555 14.5786H42.4613ZM61.5232 9.79535L58.6208 17.8977C58.4085 18.5321 58.2197 19.3612 58.2197 19.3612H58.1728C58.1728 19.3612 57.9605 18.483 57.7248 17.8729L54.7285 9.79479H52.1568L57.1589 21.9969L56.5691 23.4124C56.1211 24.535 55.3424 25.2427 54.3984 25.2427C53.6907 25.2427 53.0304 24.7054 53.0304 24.7054L52.2277 26.512C52.2277 26.512 53.1952 27.2683 54.5168 27.2683C56.192 27.2683 57.6784 26.2919 58.3861 24.364L64.0016 9.79479H61.5243L61.5232 9.79535Z"
              fill="#14325F"
            />
            <path
              d="M3.14107 38.8828C2.87387 38.7327 2.6648 38.5248 2.51333 38.26C2.36187 37.9952 2.28613 37.6962 2.28613 37.363C2.28613 37.0299 2.36293 36.7314 2.516 36.4661C2.66907 36.2013 2.8792 35.9933 3.1464 35.8433C3.4136 35.6933 3.7128 35.6177 4.044 35.6177C4.3128 35.6177 4.55813 35.6662 4.78 35.7633C5.00187 35.8604 5.18907 36.0011 5.34213 36.1848L4.94853 36.5676C4.7112 36.3028 4.41893 36.1699 4.07227 36.1699C3.8472 36.1699 3.6456 36.2206 3.46747 36.3227C3.28933 36.4242 3.15013 36.5659 3.0504 36.7469C2.95013 36.9278 2.90053 37.133 2.90053 37.3625C2.90053 37.592 2.95067 37.7972 3.0504 37.9781C3.15013 38.159 3.28933 38.3003 3.46747 38.4023C3.6456 38.5038 3.8472 38.5551 4.07227 38.5551C4.41893 38.5551 4.7112 38.4211 4.94853 38.153L5.34213 38.5408C5.18907 38.725 5.0008 38.8657 4.77733 38.9622C4.55387 39.0593 4.308 39.1079 4.0392 39.1079C3.708 39.1079 3.4088 39.0328 3.1416 38.8822L3.14107 38.8828Z"
              fill="#14325F"
            />
            <path
              d="M7.31922 36.4419V37.0189C7.26908 37.009 7.22375 37.0046 7.18322 37.0046C6.95495 37.0046 6.77682 37.0735 6.64882 37.2103C6.52082 37.3477 6.45682 37.5457 6.45682 37.8039V39.0594H5.87122V36.4711H6.42908V36.849C6.59762 36.5776 6.89468 36.4419 7.31975 36.4419H7.31922Z"
              fill="#14325F"
            />
            <path
              d="M10.1357 37.9595H8.17195C8.20608 38.1504 8.29622 38.3015 8.44128 38.4129C8.58635 38.5244 8.76715 38.5801 8.98262 38.5801C9.25728 38.5801 9.48395 38.4863 9.66208 38.2988L9.97622 38.6722C9.86368 38.8112 9.72128 38.916 9.54955 38.9872C9.37782 39.0584 9.18422 39.0937 8.96822 39.0937C8.69302 39.0937 8.45088 39.0374 8.24182 38.9238C8.03222 38.8107 7.87062 38.6529 7.75702 38.451C7.64288 38.2491 7.58582 38.0207 7.58582 37.7653C7.58582 37.5099 7.64128 37.2865 7.75222 37.0841C7.86315 36.8822 8.01675 36.7244 8.21408 36.6113C8.41088 36.4982 8.63275 36.4414 8.87968 36.4414C9.12662 36.4414 9.34155 36.4971 9.53355 36.6086C9.72555 36.72 9.87542 36.8766 9.98368 37.0785C10.0914 37.2804 10.1453 37.5138 10.1453 37.7791C10.1453 37.821 10.1421 37.8812 10.1357 37.9584V37.9595ZM8.40395 37.099C8.27435 37.2137 8.19542 37.3665 8.16715 37.5568H9.58688C9.56182 37.3693 9.48555 37.2176 9.35702 37.1012C9.22902 36.9848 9.06955 36.9268 8.87915 36.9268C8.68875 36.9268 8.53302 36.9842 8.40342 37.099H8.40395Z"
              fill="#14325F"
            />
            <path
              d="M12.4388 36.7161C12.634 36.8986 12.7316 37.1739 12.7316 37.5424V39.0594H12.1785V38.7444C12.1065 38.8575 12.0041 38.9441 11.8713 39.0037C11.7385 39.0633 11.5785 39.0936 11.3908 39.0936C11.2031 39.0936 11.0393 39.0605 10.8985 38.9943C10.7577 38.9281 10.6495 38.836 10.5727 38.7179C10.4959 38.5999 10.458 38.4669 10.458 38.318C10.458 38.0852 10.5417 37.8988 10.7087 37.7581C10.8756 37.6174 11.1391 37.5474 11.4985 37.5474H12.1455V37.5088C12.1455 37.3278 12.0932 37.1888 11.9887 37.0917C11.8841 36.9946 11.7284 36.9461 11.5225 36.9461C11.3817 36.9461 11.2436 36.9687 11.1076 37.0139C10.9716 37.0592 10.8569 37.1221 10.7631 37.2031L10.5332 36.7618C10.6644 36.6587 10.8223 36.5793 11.0068 36.5241C11.1913 36.4689 11.3865 36.4419 11.5924 36.4419C11.9609 36.4419 12.2431 36.5335 12.4383 36.7155L12.4388 36.7161ZM11.9044 38.5486C12.0185 38.4791 12.0991 38.3798 12.146 38.2507V37.9501H11.5412C11.2036 37.9501 11.0351 38.0648 11.0351 38.2943C11.0351 38.4041 11.0772 38.4912 11.1615 38.5563C11.2457 38.6208 11.3631 38.6534 11.5129 38.6534C11.6628 38.6534 11.7903 38.6186 11.9044 38.5491V38.5486Z"
              fill="#14325F"
            />
            <path
              d="M14.9865 38.9191C14.9177 38.977 14.8339 39.0206 14.7358 39.0499C14.6371 39.0791 14.5337 39.0934 14.4243 39.0934C14.1491 39.0934 13.9369 39.019 13.787 38.8706C13.6371 38.7222 13.5619 38.5054 13.5619 38.2207V36.9752H13.1497V36.4903H13.5619V35.8989H14.1475V36.4903H14.8179V36.9752H14.1475V38.2064C14.1475 38.3327 14.1774 38.4287 14.2366 38.4949C14.2958 38.5611 14.3817 38.5942 14.4942 38.5942C14.6254 38.5942 14.7347 38.5589 14.8222 38.4877L14.9865 38.9191Z"
              fill="#14325F"
            />
            <path
              d="M17.7278 37.9595H15.7641C15.7982 38.1504 15.8884 38.3015 16.0334 38.4129C16.1785 38.5244 16.3593 38.5801 16.5748 38.5801C16.8494 38.5801 17.0761 38.4863 17.2542 38.2988L17.5684 38.6722C17.4558 38.8112 17.3134 38.916 17.1417 38.9872C16.97 39.0584 16.7764 39.0937 16.5604 39.0937C16.2852 39.0937 16.043 39.0374 15.834 38.9238C15.6244 38.8107 15.4628 38.6529 15.3492 38.451C15.235 38.2491 15.178 38.0207 15.178 37.7653C15.178 37.5099 15.2334 37.2865 15.3444 37.0841C15.4553 36.8822 15.6089 36.7244 15.8062 36.6113C16.003 36.4982 16.2249 36.4414 16.4718 36.4414C16.7188 36.4414 16.9337 36.4971 17.1257 36.6086C17.3177 36.72 17.4676 36.8766 17.5758 37.0785C17.6836 37.2804 17.7374 37.5138 17.7374 37.7791C17.7374 37.821 17.7342 37.8812 17.7278 37.9584V37.9595ZM15.9961 37.099C15.8665 37.2137 15.7876 37.3665 15.7593 37.5568H17.179C17.154 37.3693 17.0777 37.2176 16.9492 37.1012C16.8212 36.9848 16.6617 36.9268 16.4713 36.9268C16.2809 36.9268 16.1252 36.9842 15.9956 37.099H15.9961Z"
              fill="#14325F"
            />
            <path
              d="M21.9366 37.9595H19.9729C20.007 38.1504 20.0971 38.3015 20.2422 38.4129C20.3873 38.5244 20.5681 38.5801 20.7835 38.5801C21.0582 38.5801 21.2849 38.4863 21.463 38.2988L21.7771 38.6722C21.6646 38.8112 21.5222 38.916 21.3505 38.9872C21.1787 39.0584 20.9851 39.0937 20.7691 39.0937C20.4939 39.0937 20.2518 39.0374 20.0427 38.9238C19.8331 38.8107 19.6715 38.6529 19.5579 38.451C19.4438 38.2491 19.3867 38.0207 19.3867 37.7653C19.3867 37.5099 19.4422 37.2865 19.5531 37.0841C19.6641 36.8822 19.8177 36.7244 20.015 36.6113C20.2118 36.4982 20.4337 36.4414 20.6806 36.4414C20.9275 36.4414 21.1425 36.4971 21.3345 36.6086C21.5265 36.72 21.6763 36.8766 21.7846 37.0785C21.8923 37.2804 21.9462 37.5138 21.9462 37.7791C21.9462 37.821 21.943 37.8812 21.9366 37.9584V37.9595ZM20.2049 37.099C20.0753 37.2137 19.9963 37.3665 19.9681 37.5568H21.3878C21.3627 37.3693 21.2865 37.2176 21.1579 37.1012C21.0299 36.9848 20.8705 36.9268 20.6801 36.9268C20.4897 36.9268 20.3339 36.9842 20.2043 37.099H20.2049Z"
              fill="#14325F"
            />
            <path
              d="M24.0307 39.0595L23.3886 38.1625L22.7417 39.0595H22.0947L23.0697 37.746L22.1369 36.4712H22.7881L23.4019 37.3196L24.0158 36.4712H24.6483L23.7113 37.7361L24.691 39.0595H24.0302H24.0307Z"
              fill="#14325F"
            />
            <path
              d="M27.164 36.6068C27.356 36.7165 27.5069 36.8716 27.6163 37.0723C27.7256 37.2726 27.7805 37.5037 27.7805 37.7657C27.7805 38.0278 27.7256 38.2595 27.6163 38.4614C27.5069 38.6633 27.356 38.8194 27.164 38.9291C26.972 39.0389 26.7539 39.0941 26.5101 39.0941C26.1725 39.0941 25.9053 38.9777 25.7085 38.7449V40.0004H25.1229V36.4716H25.6808V36.8109C25.7779 36.6879 25.8973 36.5957 26.0392 36.5345C26.1816 36.4733 26.3384 36.4424 26.5101 36.4424C26.7539 36.4424 26.972 36.4975 27.164 36.6073V36.6068ZM26.9768 38.3516C27.116 38.2032 27.1853 38.0074 27.1853 37.7652C27.1853 37.523 27.116 37.3272 26.9768 37.1788C26.8376 37.0304 26.6605 36.956 26.4451 36.956C26.3043 36.956 26.1779 36.9891 26.0653 37.0552C25.9528 37.1214 25.8637 37.2163 25.7981 37.3388C25.7325 37.4618 25.6995 37.6036 25.6995 37.7652C25.6995 37.9268 25.7325 38.0691 25.7981 38.1916C25.8637 38.3146 25.9528 38.409 26.0653 38.4751C26.1779 38.5413 26.3043 38.5744 26.4451 38.5744C26.6605 38.5744 26.8381 38.5 26.9768 38.3516Z"
              fill="#14325F"
            />
            <path
              d="M30.6817 37.9595H28.718C28.7521 38.1504 28.8422 38.3015 28.9873 38.4129C29.1324 38.5244 29.3132 38.5801 29.5286 38.5801C29.8033 38.5801 30.03 38.4863 30.2081 38.2988L30.5222 38.6722C30.4097 38.8112 30.2673 38.916 30.0956 38.9872C29.9238 39.0584 29.7302 39.0937 29.5142 39.0937C29.239 39.0937 28.9969 39.0374 28.7878 38.9238C28.5782 38.8107 28.4166 38.6529 28.303 38.451C28.1889 38.2491 28.1318 38.0207 28.1318 37.7653C28.1318 37.5099 28.1873 37.2865 28.2982 37.0841C28.4092 36.8822 28.5628 36.7244 28.7601 36.6113C28.9569 36.4982 29.1788 36.4414 29.4257 36.4414C29.6726 36.4414 29.8876 36.4971 30.0796 36.6086C30.2716 36.72 30.4214 36.8766 30.5297 37.0785C30.6374 37.2804 30.6913 37.5138 30.6913 37.7791C30.6913 37.821 30.6881 37.8812 30.6817 37.9584V37.9595ZM28.95 37.099C28.8204 37.2137 28.7414 37.3665 28.7132 37.5568H30.1329C30.1078 37.3693 30.0316 37.2176 29.903 37.1012C29.775 36.9848 29.6156 36.9268 29.4252 36.9268C29.2348 36.9268 29.079 36.9842 28.9494 37.099H28.95Z"
              fill="#14325F"
            />
            <path
              d="M32.7011 36.4419V37.0189C32.6509 37.009 32.6056 37.0046 32.5651 37.0046C32.3368 37.0046 32.1587 37.0735 32.0307 37.2103C31.9027 37.3477 31.8387 37.5457 31.8387 37.8039V39.0594H31.2531V36.4711H31.8109V36.849C31.9795 36.5776 32.2765 36.4419 32.7016 36.4419H32.7011Z"
              fill="#14325F"
            />
            <path
              d="M33.2259 35.9404C33.1539 35.8709 33.1182 35.7843 33.1182 35.6812C33.1182 35.578 33.1539 35.4914 33.2259 35.4219C33.2979 35.3524 33.387 35.3176 33.4931 35.3176C33.5992 35.3176 33.6883 35.3507 33.7603 35.4169C33.8323 35.4831 33.868 35.5664 33.868 35.6668C33.868 35.7733 33.8328 35.8632 33.7624 35.936C33.692 36.0088 33.6024 36.0452 33.4931 36.0452C33.3838 36.0452 33.2979 36.0105 33.2259 35.941V35.9404ZM33.1976 36.4711H33.7832V39.0594H33.1976V36.4711Z"
              fill="#14325F"
            />
            <path
              d="M36.8956 37.9595H34.9318C34.966 38.1504 35.0561 38.3015 35.2012 38.4129C35.3462 38.5244 35.527 38.5801 35.7425 38.5801C36.0172 38.5801 36.2438 38.4863 36.422 38.2988L36.7361 38.6722C36.6236 38.8112 36.4817 38.916 36.3094 38.9872C36.1377 39.0584 35.9441 39.0937 35.7281 39.0937C35.4529 39.0937 35.2108 39.0374 35.0017 38.9238C34.7921 38.8107 34.6305 38.6529 34.5169 38.451C34.4028 38.2491 34.3457 38.0207 34.3457 37.7653C34.3457 37.5099 34.4012 37.2865 34.5121 37.0841C34.623 36.8822 34.7766 36.7244 34.974 36.6113C35.1708 36.4982 35.3926 36.4414 35.6396 36.4414C35.8865 36.4414 36.1014 36.4971 36.2934 36.6086C36.4854 36.72 36.6358 36.8766 36.7436 37.0785C36.8513 37.2804 36.9052 37.5138 36.9052 37.7791C36.9052 37.821 36.902 37.8812 36.8956 37.9584V37.9595ZM35.1638 37.099C35.0342 37.2137 34.9553 37.3665 34.927 37.5568H36.3468C36.3217 37.3693 36.2454 37.2176 36.1169 37.1012C35.9889 36.9848 35.8294 36.9268 35.639 36.9268C35.4486 36.9268 35.2929 36.9842 35.1633 37.099H35.1638Z"
              fill="#14325F"
            />
            <path
              d="M39.6464 36.7281C39.8368 36.9189 39.9323 37.2014 39.9323 37.5765V39.0598H39.3467V37.6543C39.3467 37.4281 39.295 37.2576 39.192 37.1429C39.0891 37.0282 38.9419 36.9708 38.7515 36.9708C38.536 36.9708 38.3654 37.0381 38.2406 37.1721C38.1158 37.3062 38.0534 37.4992 38.0534 37.7513V39.0604H37.4678V36.4721H38.0256V36.8064C38.1227 36.6867 38.2443 36.5962 38.391 36.535C38.5376 36.4738 38.7035 36.4429 38.8875 36.4429C39.2032 36.4429 39.456 36.5383 39.647 36.7286L39.6464 36.7281Z"
              fill="#14325F"
            />
            <path
              d="M41.1511 38.9237C40.9447 38.8106 40.7842 38.6528 40.6685 38.4509C40.5527 38.249 40.4951 38.0207 40.4951 37.7653C40.4951 37.5099 40.5527 37.282 40.6685 37.0818C40.7842 36.8815 40.9442 36.7249 41.149 36.6118C41.3538 36.4987 41.5874 36.4419 41.8498 36.4419C42.0967 36.4419 42.3127 36.4937 42.4989 36.5969C42.6845 36.7006 42.8247 36.849 42.9181 37.0426L42.4679 37.314C42.3959 37.1943 42.3063 37.105 42.1986 37.0448C42.0909 36.9853 41.973 36.9549 41.845 36.9549C41.6263 36.9549 41.445 37.0283 41.3015 37.1756C41.1575 37.3229 41.086 37.5192 41.086 37.7647C41.086 38.0102 41.157 38.2066 41.2994 38.3539C41.4418 38.5011 41.6237 38.5745 41.8455 38.5745C41.9735 38.5745 42.0914 38.5447 42.1991 38.4846C42.3068 38.425 42.397 38.3351 42.4685 38.2154L42.9186 38.4868C42.8215 38.6804 42.6802 38.8305 42.4946 38.9353C42.3085 39.0406 42.094 39.093 41.8503 39.093C41.5911 39.093 41.3581 39.0368 41.1522 38.9231L41.1511 38.9237Z"
              fill="#14325F"
            />
            <path
              d="M45.7293 37.9595H43.7656C43.7997 38.1504 43.8898 38.3015 44.0349 38.4129C44.18 38.5244 44.3608 38.5801 44.5762 38.5801C44.8509 38.5801 45.0776 38.4863 45.2557 38.2988L45.5698 38.6722C45.4573 38.8112 45.3154 38.916 45.1432 38.9872C44.9714 39.0584 44.7778 39.0937 44.5618 39.0937C44.2866 39.0937 44.0445 39.0374 43.8354 38.9238C43.6258 38.8107 43.4642 38.6529 43.3506 38.451C43.2365 38.2491 43.1794 38.0207 43.1794 37.7653C43.1794 37.5099 43.2349 37.2865 43.3458 37.0841C43.4568 36.8822 43.6104 36.7244 43.8077 36.6113C44.0045 36.4982 44.2264 36.4414 44.4733 36.4414C44.7202 36.4414 44.9352 36.4971 45.1272 36.6086C45.3192 36.72 45.4696 36.8766 45.5773 37.0785C45.685 37.2804 45.7389 37.5138 45.7389 37.7791C45.7389 37.821 45.7357 37.8812 45.7293 37.9584V37.9595ZM43.9976 37.099C43.868 37.2137 43.789 37.3665 43.7608 37.5568H45.1805C45.1554 37.3693 45.0792 37.2176 44.9506 37.1012C44.8226 36.9848 44.6632 36.9268 44.4728 36.9268C44.2824 36.9268 44.1266 36.9842 43.997 37.099H43.9976Z"
              fill="#14325F"
            />
            <path
              d="M50.1111 39.1129L49.6989 38.7008C49.3927 38.9722 49.0258 39.1079 48.5975 39.1079C48.3693 39.1079 48.165 39.0693 47.9837 38.9915C47.8023 38.9138 47.6605 38.8067 47.557 38.6694C47.4541 38.532 47.4023 38.3759 47.4023 38.2016C47.4023 37.9914 47.4615 37.8066 47.5805 37.6467C47.6994 37.4867 47.893 37.3289 48.1618 37.1739C48.0306 37.0382 47.9367 36.9113 47.8807 36.7933C47.8247 36.6752 47.7965 36.5533 47.7965 36.4276C47.7965 36.1887 47.8829 35.9956 48.0567 35.8483C48.2301 35.7011 48.4605 35.6277 48.7479 35.6277C49.0135 35.6277 49.2258 35.695 49.3853 35.829C49.5447 35.9631 49.6242 36.1451 49.6242 36.3746C49.6242 36.5489 49.5719 36.7039 49.4674 36.8402C49.3629 36.9759 49.1917 37.1132 48.9543 37.2522L49.6621 37.9501C49.7399 37.7917 49.8013 37.6009 49.845 37.378L50.3186 37.538C50.2562 37.8386 50.1575 38.0973 50.0231 38.3136L50.4354 38.7207L50.1122 39.1135L50.1111 39.1129ZM49.3517 38.3566L48.4845 37.4988C48.2973 37.6053 48.165 37.7079 48.0882 37.8066C48.0114 37.9054 47.9735 38.0157 47.9735 38.1387C47.9735 38.281 48.0343 38.3958 48.1565 38.4829C48.2786 38.5701 48.4391 38.6137 48.6391 38.6137C48.9111 38.6137 49.1485 38.5282 49.3517 38.3566ZM48.4423 36.1584C48.3671 36.2246 48.3298 36.3112 48.3298 36.4176C48.3298 36.4954 48.3501 36.5688 48.3906 36.6383C48.4311 36.7078 48.5095 36.8021 48.6247 36.9218C48.8119 36.8154 48.9426 36.7221 49.0162 36.6432C49.0898 36.5638 49.1261 36.4778 49.1261 36.384C49.1261 36.2836 49.0925 36.2047 49.0253 36.1462C48.9581 36.0883 48.8653 36.0591 48.7463 36.0591C48.6183 36.0591 48.5165 36.0922 48.4418 36.1584H48.4423Z"
              fill="#14325F"
            />
            <path
              d="M52.1963 35.4626H52.7819V39.0593H52.1963V35.4626Z"
              fill="#14325F"
            />
            <path
              d="M53.9917 38.9237C53.7885 38.8106 53.6301 38.6528 53.5159 38.4509C53.4018 38.249 53.3447 38.0207 53.3447 37.7653C53.3447 37.5099 53.4018 37.282 53.5159 37.0818C53.6301 36.8815 53.7885 36.7249 53.9917 36.6118C54.1949 36.4987 54.4226 36.4419 54.6759 36.4419C54.9293 36.4419 55.1618 36.4987 55.365 36.6118C55.5682 36.7249 55.7266 36.8815 55.8407 37.0818C55.9549 37.282 56.0119 37.5099 56.0119 37.7653C56.0119 38.0207 55.9549 38.249 55.8407 38.4509C55.7266 38.6528 55.5682 38.8106 55.365 38.9237C55.1618 39.0368 54.9325 39.0936 54.6759 39.0936C54.4194 39.0936 54.1949 39.0373 53.9917 38.9237ZM55.2103 38.3516C55.3511 38.2033 55.421 38.0074 55.421 37.7653C55.421 37.5231 55.3506 37.3273 55.2103 37.1789C55.0695 37.0305 54.8914 36.956 54.6759 36.956C54.4605 36.956 54.2829 37.0305 54.1442 37.1789C54.005 37.3278 53.9357 37.5231 53.9357 37.7653C53.9357 38.0074 54.005 38.2033 54.1442 38.3516C54.2834 38.5006 54.4605 38.5745 54.6759 38.5745C54.8914 38.5745 55.0695 38.5 55.2103 38.3516Z"
              fill="#14325F"
            />
            <path
              d="M58.8471 36.4712L57.774 39.0595H57.1697L56.0967 36.4712H56.7057L57.4839 38.3909L58.2855 36.4712H58.8476H58.8471Z"
              fill="#14325F"
            />
            <path
              d="M61.4854 37.9595H59.5217C59.5558 38.1504 59.6459 38.3015 59.791 38.4129C59.9361 38.5244 60.1169 38.5801 60.3323 38.5801C60.607 38.5801 60.8337 38.4863 61.0118 38.2988L61.3259 38.6722C61.2134 38.8112 61.0715 38.916 60.8993 38.9872C60.7275 39.0584 60.5339 39.0937 60.3179 39.0937C60.0427 39.0937 59.8006 39.0374 59.5915 38.9238C59.3819 38.8107 59.2203 38.6529 59.1067 38.451C58.9926 38.2491 58.9355 38.0207 58.9355 37.7653C58.9355 37.5099 58.991 37.2865 59.1019 37.0841C59.2129 36.8822 59.3665 36.7244 59.5638 36.6113C59.7606 36.4982 59.9825 36.4414 60.2294 36.4414C60.4763 36.4414 60.6913 36.4971 60.8833 36.6086C61.0753 36.72 61.2257 36.8766 61.3334 37.0785C61.4411 37.2804 61.495 37.5138 61.495 37.7791C61.495 37.821 61.4918 37.8812 61.4854 37.9584V37.9595ZM59.7537 37.099C59.6241 37.2137 59.5451 37.3665 59.5169 37.5568H60.9366C60.9115 37.3693 60.8353 37.2176 60.7067 37.1012C60.5787 36.9848 60.4193 36.9268 60.2289 36.9268C60.0385 36.9268 59.8827 36.9842 59.7531 37.099H59.7537Z"
              fill="#14325F"
            />
          </svg>
        </Link>
        <div className={"nav__box" + (nav ? " active" : "")}>
          <div className="nav__bl">
            <div className="nav__menu">
              <ul>
                {home && (
                  <>
                    <li>
                      <a
                        onClick={() => {
                          goToAnchor("about");
                        }}
                      >
                        Об агентстве
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          goToAnchor("facts");
                        }}
                      >
                        Факты
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          goToAnchor("cases");
                        }}
                      >
                        Кейсы
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          goToAnchor("advants");
                        }}
                      >
                        Преимущества
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          goToAnchor("resources");
                        }}
                      >
                        Ресурсы
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          goToAnchor("services");
                        }}
                      >
                        Услуги
                      </a>
                    </li>
                  </>
                )}
                {!home && (
                  <>
                    <li>
                      <Link to="/#about">Об агентстве</Link>
                    </li>
                    <li>
                      <Link to="/#facts">Факты</Link>
                    </li>
                    <li>
                      <Link to="/#cases">Кейсы</Link>
                    </li>
                    <li>
                      <Link to="/#advants">Преимущества</Link>
                    </li>
                    <li>
                      <Link to="/#resources">Ресурсы</Link>
                    </li>
                    <li>
                      <Link to="/#services">Услуги</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link to="/contacts">Контакты</Link>
                </li>
                <li className="desk-only">
                  {home && (
                    <a
                      onClick={() => {
                        goToAnchor("about");
                      }}
                    >
                      <div className="logo">
                        <img src={require("../assets/img/logo.svg").default} />
                      </div>
                    </a>
                  )}
                  {!home && (
                    <Link to="/">
                      <div className="logo">
                        <img src={require("../assets/img/logo.svg").default} />
                      </div>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
